import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { isAuthenticated } from '../../components/Auth/auth';
import { ErrorMessage } from '../../components/Messages/messages';

export const Results = () => {
    const [data, setData] = useState([]);
    const [latestCalculation, setLatestCalculation] = useState({});

    const getData = async () => {
        await axios.get(`/api/calculations/get/${isAuthenticated()._id}`, {
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.status === 200) {
                setData(res.data);
                setLatestCalculation(res.data[res.data.length - 1]);
            } else {
                ErrorMessage(res.data.errorMessage);
            }
        })
    }

    console.log(data)
    console.log(latestCalculation)
    useEffect(() => {
        getData();

        return () => {

        }
    }, [])


    return (
        <div className='Results'>
            <div>
                <h2 className='mb-0'>Current Cost Breakdown: </h2>
                <br></br>
                <div className='d-flex justify-content-center gap-4'>
                    <div><b>Part Weight: </b>{latestCalculation.partWeight}</div>
                    <div><b>Part Volume: </b>{latestCalculation.calculatedPartVolume}</div>
                    <div><b>Metal Loss: </b>1.05</div>
                </div>
                <br></br>
                <div className="table-responsive">
                    <table className="table table-bordered border-primary">
                    <thead class="thead-dark">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Cost amount in US dollars ($)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Processing cost</td>
                      <td>{latestCalculation.calculatedPC}</td>
                    </tr>
                    <tr>
                      <td>NDT cost</td>
                      <td>{latestCalculation.calculatedNDTC}</td>
                    </tr>
                    <tr>
                      <td>Total part cost</td>
                      <td>{latestCalculation.calculatedTPC}</td>
                    </tr>
                    <tr>
                      <td>Material cost</td>
                      <td>{latestCalculation.calculatedMC}</td>
                    </tr>

                    <tr>
                      <td>Casting process cost</td>
                      <td>{latestCalculation.calculatedCPC}</td>
                    </tr>
                    <tr>
                      <td>Indirect material cost</td>
                      <td>{latestCalculation.calculatedIMC}</td>
                    </tr>
                    <tr>
                      <td>Molding sand cost</td>
                      <td>{latestCalculation.calculatedMSC}</td>
                    </tr>
                    <tr>
                      <td>Wax cost</td>
                      <td>{latestCalculation.calculatedWC}</td>
                    </tr>
                    <tr>
                      <td>Molding cost</td>
                      <td>{latestCalculation.calculatedMoC}</td>
                    </tr>
                    <tr>
                      <td>Pouring cost</td>
                      <td>{latestCalculation.calculatedPoC}</td>
                    </tr>
                    <tr>
                      <td>Fettling cost</td>
                      <td>{latestCalculation.calculatedFC}</td>
                    </tr>
                    <tr>
                      <td>Other tasks cost</td>
                      <td>{latestCalculation.calculatedOTC}</td>
                    </tr>
                    <tr>
                      <td>Melting cost</td>
                      <td>{latestCalculation.calculatedMeC}</td>
                    </tr>
                    <tr>
                      <td>Energy cost</td>
                      <td>{latestCalculation.calculatedEC}</td>
                    </tr>
                    <tr>
                      <td>Heat treatment cost</td>
                      <td>{latestCalculation.calculatedHTC}</td>
                    </tr>
                    <tr>
                      <td>Straightening check cost</td>
                      <td>{latestCalculation.calculatedSC}</td>
                    </tr>
                    <tr>
                      <td>Wax related cost</td>
                      <td>{latestCalculation.calculatedWRC}</td>
                    </tr>
                    <tr>
                      <td>X-ray cost</td>
                      <td>{latestCalculation.calculatedXRC}</td>
                    </tr>
                    <tr>
                      <td>Dye penetrant cost</td>
                      <td>{latestCalculation.calculatedDPC}</td>
                    </tr>

                    </tbody>
                    </table>
                </div>

                <h2>History </h2>
                <div className="table-responsive">
                    <table className="table table-bordered border-primary">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Part Number</th>
                                <th scope="col">Part Material</th>
                                <th scope="col">Grade</th>
                                <th scope="col">Class</th>
                                <th scope="col">Part Volume</th>
                                <th scope="col">Part Weight</th>
                                <th scope="col">Part Complexity</th>
                                <th scope="col">Total Part Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.length > 1 && data.slice(0, -1).map((d, index) => {
                                    return (
                                        <tr key={index}>
                                            <td scope="row">{index + 1}</td>
                                            <td>{d.partNumber}</td>
                                            <td>{d.partMaterial}</td>
                                            <td>{d.grade}</td>
                                            <td>{d.partClass}</td>
                                            <td>{d.calculatedPartVolume} inches</td>
                                            <td>{d.partWeight} lbs</td>
                                            <td>{d.partComplexity}</td>
                                            <td>{d.calculatedTPC}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
