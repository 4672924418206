import React, { useState } from 'react';
import { Loading } from '../../components/Loading/Loading';
import { ErrorMessage, SuccessMessage, WarningMessage } from '../../components/Messages/messages';
import axios from 'axios';
import { isAuthenticated } from '../../components/Auth/auth';

export const Home = (props) => {
  const [loading, setLoading] = useState(false);
  
  const [data, setData] = useState({
    partNumber: '',
    partMaterial: 'AI-356',
    grade: 'A',
    partClass: '1',
    partQuantity: '',
    length: '',
    width: '',
    height: '',
    partWeight: '',
    surfaceArea: '',
    partComplexity: '',
    partEnvelopeVolume: ''
  });

  const {
    partNumber,
    partMaterial,
    grade,
    partClass,
    partQuantity,
    length,
    width,
    height,
    partWeight,
    surfaceArea,
    partComplexity,
    partEnvelopeVolume
  } = data;

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  }

  // const handleDropdownChange = (event) => {
  //   this.setState({
  //       ...this.state,
  //       value: event.target.value
  //     })
  // }

  const submitHandler = (e) => {
    e.preventDefault();
    if (isAuthenticated()) {
      setLoading(true);
      axios.post('/api/calculations/create', {
        partNumber,
        partMaterial,
        grade,
        partClass,
        partQuantity,
        length,
        width,
        height,
        partWeight,
        surfaceArea,
        partComplexity,
        partEnvelopeVolume
      }, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          setLoading(false);
          SuccessMessage(res.data.successMessage);
          props.history.push('/results');
        }
        else {
          ErrorMessage(res.data.errorMessage);
        }
      })
    } else {
      WarningMessage('Please log in to run cost calculations & save data to the backend.')
    }
  }

  return (
    <div className='home'>
    <form onSubmit={submitHandler}>
    <h4><b><i>Cost Advisor for Aluminum Investment Castings</i></b></h4>
    <br/>

    <div className="form-floating">
    <input name='partNumber' type="text" className="form-control" required onChange={handleChange} id="floatingPassword" placeholder="partNumber" />
    <label for="floatingPassword">Part Number</label>
    </div>
    <br/>
    
    <div>
    <h5>Material Section:</h5>

    <label className="label-styles" for="partMaterial">Part Material</label>
    <select name='partMaterial' className="form-control" required id="partMaterial" onChange={handleChange} placeholder="Part Material">
    <option value='AI-356'> AI-356 </option>
    <option value='AI-535'> AI-535 </option>
    <option value='BQ-111'> BQ-111 </option>
    </select>
    <br/>

    <label className="label-styles" for="grade">Grade</label>
    <select name='grade' className="form-control" required id="grade" onChange={handleChange} placeholder="Grade">
    <option value='A'> A </option>
    <option value='B'> B </option>
    <option value='C'> C </option>
    <option value='D'> D </option>
    </select>
    <br/>

    <label className="label-styles" for="class">Class</label>
    <select name='partClass' className="form-control" required id="class" onChange={handleChange} placeholder="Class">
    <option value='1'> 1 </option>
    <option value='2'> 2 </option>
    <option value='3'> 3 </option>
    <option value='4'> 4 </option>
    </select>
    <br/>
    <br />


    <div className="form-floating">
    <input name='partQuantity' type="text" className="form-control" required onChange={handleChange} id="partQuant" placeholder="Part Quantity" />
    <label for="partQuant">Part Quantity</label>
    </div>
    
    </div>
    <br />

    <div>
    <div className='row my-3 mt-5'>
    <div className='col-md-6'>
    <h5>Enter Box Volume Dimensions:</h5>
    </div>
    <div className='col-md-6 align-self-center'>
    <button className='btn btn-primary w-100'>How to enter Box Dimensions</button>
    </div>
    </div>
    <div className="form-floating mb-3">
    <input name='length' type="text" className="form-control" required onChange={handleChange} id="partLen" placeholder="Length (inches)" />
    <label for="partLen">Length (inches)</label>
    </div>
    <div className="form-floating">
    <input name='width' type="text" className="form-control" required onChange={handleChange} id="partWid" placeholder="Width (inches)" />
    <label for="partWid">Width (inches)</label>
    </div>
    <div className="form-floating">
    <input name='height' type="text" className="form-control" required onChange={handleChange} id="partHeight" placeholder="Height (inches)" />
    <label for="partHeight">Height (inches)</label>
    </div>
    <div className="form-floating">
    <input name='partWeight' type="text" className="form-control" required onChange={handleChange} id="partWei" placeholder="Enter Part Weight (lbs)" />
    <label for="partWei">Enter Part Weight (lbs)</label>
    </div>

    <div className='row my-3 mt-5'>
    <div className='col-md-6 mb-4 mb-md-0'>
    <div className="form-floating">
    <input name='surfaceArea' type="text" className="form-control" required onChange={handleChange} id="area" placeholder="Surface Area (Sq. Inch)" />
    <label for="area">Surface Area (Sq. Inch)</label>
    </div>
    </div>
    <div className='col-md-6 align-self-center'>
    <button className='btn btn-primary w-100'>How to calculate Surface Area</button>
    </div>
    </div>

    <div className='row my-3 mt-5'>
    <div className='col-md-6 mb-4 mb-md-0'>
    <div className="form-floating">
    <input name='partComplexity' type="text" className="form-control" required onChange={handleChange} id="complexity" placeholder="Part Shape Complexity" />
    <label for="complexity">Part Shape Complexity</label>
    </div>
    </div>
    <div className='col-md-6 align-self-center'>
    <button className='btn btn-primary w-100'>How to determine complexity</button>
    </div>
    </div>



    <div className='row my-3 mt-5'>
    <div className='col-md-6 mb-4 mb-md-0'>
    <div className="form-floating">
    <input name='partEnvelopeVolume' type="text" className="form-control" required onChange={handleChange} id="enVol" placeholder="Part Envelope Volume" />
    <label for="enVol">Part Envelope Volume</label>
    </div>
    </div>
    <div className='col-md-6 align-self-center'>
    <button className='btn btn-primary w-100'>How to calculate Part Env. Vol.</button>
    </div>
    </div>

    <div className='row my-3 mt-5'>
    <div className='col-md-6 align-self-center'>
    <button disabled={false} type='submit' className='btn btn-primary w-100'>
    {
      loading ?
      <Loading />
      :
      <span>Submit</span>
    }
    </button>
    </div>
    </div>
    </div>
    <br /> <br /> 
    <h4>Key Value Calculations</h4>

    <div className="table-responsive">
    <table className="table table-bordered border-primary">
    <thead class="thead-dark">
    <tr>
      <th scope="col"></th>
      <th scope="col">Cubic inches</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>Box Volume</td>
      <td>0</td>
    </tr>
    <tr>
      <td>Part Volume</td>
      <td>0</td>
    </tr>
    <tr>
      <td>Core Volume</td>
      <td>0</td>
    </tr>
    </tbody>
    </table>
    </div>


    </form>
    </div>
    )
}
